$brand: #595959;
$white: #ffffff;

.container {
    width: 1040px;
    margin: 0 auto;
}
a {
    text-decoration: none;
    transition: 0.3s ease;
}

.info {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: middle;
  flex-direction: column;
  
  p {
    text-align: center;
  }
}

#block-views-content-lifts-front-places {
    .view-content-lifts {
        padding: 20px 0;
    }
}

#places {
    header.condensed {
        background-color: $brand;
        padding: 10px;
    }
    
    h2 {
        font-size: 1em;
        padding: 6px 12px;
        margin: 0;
        margin-right: 12px;
        color: $white;
    }

    option {
        color: $brand;
    }
}

.places {
    &__container {
        display: flex;
        align-items: center;
        flex-basis: 100%;
        background: $brand;
        padding: 10px;
        margin: 0;

        @media screen and (min-width: 768px) {
            padding: 0;
            flex-basis: 90%;
        }
    }

    &__dropdown {
        align-items: center;

        @media screen and (min-width: 768px) {
            display: flex;
        }
    }

    &__nav.condensed {
        display: flex;
        flex-wrap: unset;
        padding: 0;
    }

    &__side-arrow {
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid $brand;
        display: none;
        content: "";
        z-index: 999;
        flex-basis: 10%;

        @media screen and (min-width: 768px) {
            display: inline-block;
        }
    }
}

